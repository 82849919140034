.texts-to-top-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0;
}

.texts-to-top-item-enter {
  opacity: 0;
  transform: translateY(20px);
}

.texts-to-top-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 500ms,
    transform 500ms;
}
